<template>
  <div id="rate">
    <TopBar class="background topTitle">
      <template v-slot:leftInfo>
        <svg @click="toLastIndex" t="1601170867949" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4264" width="24" height="24">
          <path d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z" p-id="4265" fill="#ffffff"></path>
        </svg>
      </template>
      <template v-slot:middleInfo>
        <span class="topTitle">扣率明细</span>
      </template>
      <template v-slot:rightInfo></template>
    </TopBar>
    <van-tabs class="vanTabs" :ellipsis="false">
      <van-tab v-for="(item,index) in vanTabsList" :title="item.title" :key="index">
        内容 {{ index }}
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
export default {
  data() {
    return {
      vanTabsList: [
        {
          title: "预约及时率",
        },
        {
          title: "预约达成率",
        },
        {
          title: "按时完成率",
        },
        {
          title: "48小时完成率",
        },
        {
          title: "核销率",
        },
      ],
    };
  },
  components: {
    TopBar,
  },
  methods: {
    toLastIndex() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less">
#rate {
  .vanTabs {
    margin-top: 45px;
  }
}
</style>